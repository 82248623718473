import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useKeycloakRoles from "../../utils/useKeycloakRoles";

const ProtectedPage = ({children, roles}) => {
  const {userInRoles} = useKeycloakRoles()

  if (userInRoles(roles)) {
    return children
  }

  return <Navigate to='/' replace />
}

ProtectedPage.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.element
};

export default ProtectedPage;
