import React, {Suspense, useEffect} from 'react';
import axios from "axios";
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useKeycloak, } from "@react-keycloak/web";
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';

const App = () => {
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
      axios.defaults.baseURL = process.env.REACT_APP_SERVICES_URL

      axios.interceptors.request.use(
          (config) => {
              const kcToken = keycloak?.token ?? '';

              config.headers.Authorization = initialized ? `Bearer ${kcToken}` : undefined
              return config;
          },
          (error) => {
              return Promise.reject(error);
          }
      );

      axios.interceptors.response.use(
          (response) => response,
          async (error) => {
              console.log("Error happened")
              console.log(error)

            if (error.response.status === 401) {
              console.log("Received 404 response, due to invalid token, trying to recover and refresh.")

              await keycloak?.updateToken(5).then(refreshed => {
                if (refreshed) {
                  console.log("Token has been refreshed.")
                } else {
                  console.log("Token is still valid.")
                }
              }).catch(() => {
                console.log("Unable to refresh token... logging out.")
                keycloak.logout()
                return Promise.reject(error);
              })

              return axios(error.config)
            }

            return Promise.reject(error);
          }
      );
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        {routing}
      </div>
    </Suspense>
  );
};

export default App;
