export const Role = {
  'Admin': 'vision.admin',
  'User': 'vision.user',

  'DeliveryBlocksViewer': 'vision.globgranit.delivery.blocks.viewer',
  'DeliveryBlocksEditor': 'vision.globgranit.delivery.blocks.editor',

  'DeliveryRawPlatesViewer': 'vision.globgranit.delivery.raw-plates.viewer',
  'DeliveryRawPlatesEditor': 'vision.globgranit.delivery.raw-plates.editor',

  'DeliveryPolishedPlatesViewer': 'vision.globgranit.delivery.polished-plates.viewer',
  'DeliveryPolishedPlatesEditor': 'vision.globgranit.delivery.polished-plates.editor',

  'DeliveryFiredPlatesViewer': 'vision.globgranit.delivery.fired-plates.viewer',
  'DeliveryFiredPlatesEditor': 'vision.globgranit.delivery.fired-plates.editor',

  'ProcessingBlockSlicingViewer': 'vision.globgranit.processing.block-slicing.viewer',
  'ProcessingBlockSlicingEditor': 'vision.globgranit.processing.block-slicing.editor',

  'ReportsStockViewer': 'vision.reports.stock.viewer',
  'ReportsStockEditor': 'vision.reports.stock.editor',

  'ReportsEventsViewer': 'vision.reports.events.viewer',
  'ReportsEventsEditor': 'vision.reports.events.editor',

  'ReportsSlicedBlocksViewer': 'vision.globgranit.reports.sliced-blocks.viewer',
  'ReportsSlicedBlocksEditor': 'vision.globgranit.reports.sliced-blocks.editor',

  'ReportsPolishedPlatesViewer': 'vision.globgranit.reports.polished-plates.viewer',
  'ReportsPolishedPlatesEditor': 'vision.globgranit.reports.polished-plates.editor',

  'InventoriesViewer': 'vision.inventories.viewer',
  'InventoriesEditor': 'vision.inventories.editor',

  'TransfersViewer': 'vision.transfers.viewer',
  'TransfersEditor': 'vision.transfers.editor',

  'TagLabelsViewer': 'vision.globgranit.tag-labels.viewer',
  'TagLabelsEditor': 'vision.globgranit.tag-labels.editor',
}

export const Feature = {
  'Globgranit': 'globgranit',
  'Core': 'core'
}