import { useKeycloak } from "@react-keycloak/web";

function useKeycloakRoles() {
  const {keycloak} = useKeycloak()

  const userInRoles = roles => {
    if (roles === undefined) {
      return true;
    }

    return roles.map(role => keycloak.hasRealmRole(role)).find((roleResult) => roleResult === true) !== undefined
  }

  return {userInRoles}
}

export default useKeycloakRoles