import { lazy } from "react";
import {Navigate} from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import GlobgranitFeature from "../views/globgranit/GlobgranitFeature";
import ProtectedPage from "../layouts/loader/ProtectedPage";
import { Role } from "../utils/roles";

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));

/***** Pages ****/
const Starter = Loadable(lazy(() => import("../views/Starter")));
const BlockDelivery = Loadable(lazy(() => import("../views/deliveries/block/BlockDelivery")));
const PlatesDelivery = Loadable(lazy(() => import("../views/deliveries/PlatesDelivery")));
const PlatesDeliveryTagLabels = Loadable(lazy(() => import("../views/deliveries/PlatesDeliveryTagLabels")));
const BasePlatesDelivery = Loadable(lazy(() => import("../views/deliveries/BasePlatesDelivery")));
const BasePage = Loadable(lazy(() => import("../views/BasePage")));
const BlockSlicing = Loadable(lazy(() => import("../views/processing/BlockSlicing")));
const BlockSlicingTagLabels = Loadable(lazy(() => import("../views/processing/BlockSlicingTagLabels")));
const MobileReleases = Loadable(lazy(() => import("../views/mobile-releases/MobileReleases")));
const TagLabels = Loadable(lazy(() => import("../views/labels/TagLabels")));
const Dictionaries = Loadable(lazy(() => import("../views/administration/Dictionaries")));
const Dictionary = Loadable(lazy(() => import("../views/administration/Dictionary")));
const EditDictionary = Loadable(lazy(() => import("../views/administration/EditDictionary")));
const Locations = Loadable(lazy(() => import("../views/administration/Locations")));
const EditLocation = Loadable(lazy(() => import("../views/administration/EditLocation")));
const SubLocations = Loadable(lazy(() => import("../views/administration/SubLocations")));
const EditSubLocation = Loadable(lazy(() => import("../views/administration/EditSubLocation")));
const StockReport = Loadable(lazy(() => import("../views/reports/StockReport")));
const EventsReport = Loadable(lazy(() => import("../views/reports/EventsReport")));
const GlobgranitStockReport = Loadable(lazy(() => import("../views/globgranit/reports/GlobgranitStockReport")));
const GlobgranitSlicedPlatesReport = Loadable(lazy(() => import("../views/globgranit/reports/SlicedPlatesReport")));
const GlobgranitPolishedPlatesReport = Loadable(lazy(() => import("../views/globgranit/reports/PolishedPlatesReport")));
const ItemDetails = Loadable(lazy(() => import("../views/items/ItemDetails")));
const GlobgranitCars = Loadable(lazy(() => import("../views/globgranit/administration/Cars")));
const GlobgranitEditCar = Loadable(lazy(() => import("../views/globgranit/administration/EditCar")));
const GlobgranitDrivers = Loadable(lazy(() => import("../views/globgranit/administration/Drivers")));
const GlobgranitEditDriver = Loadable(lazy(() => import("../views/globgranit/administration/EditDriver")));
const Inventories = Loadable(lazy(() => import("../views/inventories/Inventories")));
const InventoryDetails = Loadable(lazy(() => import("../views/inventories/InventoryDetails")));
const Transfers = Loadable(lazy(() => import("../views/transfers/Transfers")));
const TransferDetails = Loadable(lazy(() => import("../views/transfers/TransferDetails")));
const GlobgranitTransferDetailsTagLabels = Loadable(lazy(() => import("../views/globgranit/transfers/TransferDetailsTagLabels")));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/starter" /> },
      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/globgranit", exact: true, element: <GlobgranitFeature />,
        children: [
          { path: "deliveries/blocks", exact: true, element: <ProtectedPage roles={[Role.DeliveryBlocksEditor]}><BlockDelivery /></ProtectedPage>},
          { path: "deliveries/raw-plates", exact: true, element: <ProtectedPage roles={[Role.DeliveryRawPlatesEditor]}><BasePlatesDelivery /></ProtectedPage>,
            children: [
              { index: true, exact: true, element: <PlatesDelivery header="Przyjęcie płyt surowych" itemCategory="RAW_PLATE" /> },
              { path: ":itemReferenceNumberParam/tag-labels", exact: true, element: <PlatesDeliveryTagLabels header="Przyjęcie płyt surowych" returnPath="/globgranit/deliveries/raw-plates" itemCategory="RAW_PLATE" /> },
            ]
          },
          { path: "deliveries/polished-plates", exact: true, element: <ProtectedPage roles={[Role.DeliveryPolishedPlatesEditor]}><BasePlatesDelivery /></ProtectedPage>,
            children: [
              { index: true, exact: true, element: <PlatesDelivery header="Przyjęcie płyt polerowanych" itemCategory="POLISHED_PLATE" /> },
              { path: ":itemReferenceNumberParam/tag-labels", exact: true, element: <PlatesDeliveryTagLabels header="Przyjęcie płyt polerowanych" returnPath="/globgranit/deliveries/polished-plates" itemCategory="POLISHED_PLATE" /> },
            ]
          },
          { path: "deliveries/fired-plates", exact: true, element: <ProtectedPage roles={[Role.DeliveryFiredPlatesEditor]}><BasePlatesDelivery /></ProtectedPage>,
            children: [
              { index: true, exact: true, element: <PlatesDelivery header="Przyjęcie płyt płomieniowanych" itemCategory="FIRED_PLATE" /> },
              { path: ":itemReferenceNumberParam/tag-labels", exact: true, element: <PlatesDeliveryTagLabels header="Przyjęcie płyt płomieniowanych" returnPath="/globgranit/deliveries/fired-plates" itemCategory="FIRED_PLATE" /> },
            ]
          },
          {
            path: "processing/block-slicing",
            exact: true,
            element: <ProtectedPage roles={[Role.ProcessingBlockSlicingEditor]}><BlockSlicing /></ProtectedPage>
          },
          {
            path: "processing/block-slicing/:itemReferenceNumberParam",
            exact: true,
            element: <ProtectedPage roles={[Role.ProcessingBlockSlicingEditor]}><BlockSlicing /></ProtectedPage>
          },
          {
            path: "processing/block-slicing/:itemReferenceNumberParam/tag-labels",
            exact: true,
            element: <ProtectedPage roles={[Role.ProcessingBlockSlicingEditor]}><BlockSlicingTagLabels /></ProtectedPage>
          },
          { path: "reports/stock", exact: true, element: <ProtectedPage roles={[Role.ReportsStockViewer]}><GlobgranitFeature /></ProtectedPage>, children: [
              { index: true, exact: true, element: <GlobgranitStockReport /> },
              { path: ":pageIndex", exact: true, element: <GlobgranitStockReport />},
              { path: ":pageIndex/items/:itemId", exact: true, element: <ItemDetails />},
            ]
          },
          { path: "reports/sliced-plates", exact: true, element: <ProtectedPage roles={[Role.ReportsSlicedBlocksViewer]}><GlobgranitSlicedPlatesReport /></ProtectedPage>},
          { path: "reports/polished-plates", exact: true, element:  <ProtectedPage roles={[Role.ReportsPolishedPlatesViewer]}><GlobgranitPolishedPlatesReport /></ProtectedPage>},
          { path: "administration", exact: true, element: <ProtectedPage roles={[Role.Admin]}><GlobgranitFeature /></ProtectedPage>,
            children: [
              { index: true, exact: true, element: <GlobgranitFeature /> },
              { path: "cars", exact: true, element: <GlobgranitCars />},
              { path: "car", exact: true, element: <GlobgranitEditCar />,
                children: [
                  { index: true, exact: true, element: <GlobgranitEditCar /> },
                  { path: ":carId", exact: true, element: <GlobgranitEditCar />},
                ]
              },
              { path: "drivers", exact: true, element: <GlobgranitDrivers />},
              { path: "driver", exact: true, element: <GlobgranitEditDriver />,
                children: [
                  { index: true, exact: true, element: <GlobgranitEditDriver /> },
                  { path: ":driverId", exact: true, element: <GlobgranitEditDriver />},
                ]
              },
            ]
          },
          { path: "tag-labels", exact: true, element: <ProtectedPage roles={[Role.TagLabelsEditor]}><TagLabels /></ProtectedPage> },
          { path: "tag-labels/:itemReferenceNumberParam", exact: true, element: <ProtectedPage roles={[Role.TagLabelsEditor]}><TagLabels /></ProtectedPage> },
          {
            path: "transfers/:transferId/tag-labels",
            exact: true,
            element: <GlobgranitTransferDetailsTagLabels />
          },
          { path: "items/:itemId", exact: true, element: <ItemDetails /> },
        ]
      },
      { path: "/reports/stock", exact: true, element: <ProtectedPage roles={[Role.ReportsStockViewer]}><StockReport /></ProtectedPage> },
      { path: "/reports/events", exact: true, element: <ProtectedPage roles={[Role.ReportsEventsViewer]}><EventsReport /></ProtectedPage> },
      { path: "/administration/mobile-releases", exact: true, element: <ProtectedPage roles={[Role.Admin]}><MobileReleases /></ProtectedPage> },
      { path: "/administration/dictionaries", exact: true, element: <ProtectedPage roles={[Role.Admin]}><BasePage /></ProtectedPage>,
        children: [
          { index: true, exact: true, element: <Dictionaries /> },
          { path: ":dictionaryNameId", exact: true, element: <Dictionary />},
          { path: ":dictionaryNameId/values", exact: true, element: <EditDictionary />},
          { path: ":dictionaryNameId/values/:id", exact: true, element: <EditDictionary />},
        ]
      },
      { path: "/administration/location", exact: true, element: <ProtectedPage roles={[Role.Admin]}><BasePage /></ProtectedPage>,
        children: [
          { index: true, exact: true, element: <EditLocation /> },
          { path: ":locationId", exact: true, element: <EditLocation />},
        ]
      },
      { path: "/administration/locations", exact: true, element: <ProtectedPage roles={[Role.Admin]}><BasePage /></ProtectedPage>,
        children: [
          { index: true, exact: true, element: <Locations /> },
          { path: ":locationId/sub-locations", exact: true, element: <SubLocations />},
          { path: ":locationId/sub-location", exact: true, element: <BasePage />,
            children: [
              { index: true, exact: true, element: <EditSubLocation /> },
              { path: ":subLocationId", exact: true, element: <EditSubLocation />},
            ]
          },
        ]
      },
      {
        path: "/inventories", exact: true, element: <ProtectedPage roles={[Role.InventoriesViewer]}><BasePage /></ProtectedPage>,
        children: [
          { index: true, exact: true, element: <Inventories /> },
          { path: ":inventoryCycleId", exact: true, element: <InventoryDetails />},
        ]
      },
      {
        path: "/transfers", exact: true, element: <ProtectedPage roles={[Role.TransfersViewer]}><BasePage /></ProtectedPage>,
        children: [
          { index: true, exact: true, element: <Transfers /> },
          { path: ":transferId", exact: true, element: <TransferDetails />},
        ]
      },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
