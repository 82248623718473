import React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';
import keycloak from './keycloak'
import Loader from "./layouts/loader/Loader";

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer); // createRoot(container!) if you use TypeScript

const onKeycloakEvent = (event, error) => {
  console.log('onKeycloakEvent', event, error)
}

root.render(
    <ReactKeycloakProvider onEvent={onKeycloakEvent} isLoadingCheck={(k) => !k.authenticated} authClient={keycloak} initOptions={{ onLoad: 'login-required', checkLoginIframe: false }} LoadingComponent={<Loader />}>
    {/*<ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }} LoadingComponent={<Loader />}>*/}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
